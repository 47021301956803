import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { css } from 'styled-components';

const Text = ({ children, ...props }) => {
  const { lang } = useSelector(state => state.language);
  
  return (
    <Root {...props} lang={lang} >
      { children }
    </Root>
  )
}

const Root = styled.div`
  ${({ lang }) => lang === 'en' && css`
    line-height: 1.3;
  `}
  ${({ lang }) => lang === 'zh-TW' && css`
    line-height: 1.5;
  `}

`

export default Text;