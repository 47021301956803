import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import SVGTextAnimation from '../../components/SVGTextAnimation';
import { colors } from '../../constants/colors';
import useWording from '../../utils/useWording';
import { respondFrom, respondTo } from '../../utils/responsive';
import Marquee from '../../components/Marquee';

const IllustrationSection = ({ isPush, ...props }) => {
  const wording = useWording('homepage');

  const rootRef = useRef(null);
  const timerRef = useRef(null);
  const currentIndexRef = useRef(null);
  const [ currentIndex, setCurrentIndex ] = useState(null);
  const [ offsetTop, setOffsetTop ] = useState();
  
  useEffect(() => {
    handleDetectMobileAutoPlay();
    setOffsetTop(rootRef.current.offsetTop);
    return () => handleDetectMobileAutoPlay();
  }, [])

  function handleDetectMobileAutoPlay() {
    if (isMobile) {
      if (currentIndex === null) {
        setCurrentIndex(0);
        currentIndexRef.current = 0;
      }
      const during = wording.illustration[currentIndexRef.current]?.during || 10;
      timerRef.current = setInterval(
        handleLoopAnimation,
        during * 1000);
    }
    else {
      timerRef.current &&
      clearInterval(timerRef.current);
    }
  }
  
  function handleLoopAnimation(start = 0) {
    if (currentIndexRef.current < wording.illustration.length-1) {
      setCurrentIndex(currentIndexRef.current + 1);
      currentIndexRef.current += 1;
    }
    else {
      setCurrentIndex(start);
      currentIndexRef.current = start;
    }
  }
  
  
  function handleHoverHeading(e) {
    if (!isMobile) {
      const index = Number(e.currentTarget.dataset.index);
      setCurrentIndex(index);
    }
  }
  
  function handleLeaveHeading() {
    if (!isMobile) {
      setCurrentIndex(null);
    }
  }

  function handleClickHeading(e) {
    if (isMobile) {
      const index = Number(e.currentTarget.dataset.index);
      const during = wording.illustration[currentIndexRef.current]?.during || 10;
      
      if (timerRef.current) clearInterval(timerRef.current);

      setCurrentIndex(index);
      currentIndexRef.current = index;

      timerRef.current = setInterval(
        handleLoopAnimation
      , during * 1000);
    }
  }
  
  return (
    <Root ref={rootRef} {...props}>
      <Background
      display={currentIndex !== null ? 'show' : 'hide'}
      push={isPush}
      offsetTop={offsetTop}>
        { currentIndex !== null &&
          <SVGTextAnimation
            className="animation"
            key={currentIndex}
            during={wording.illustration[currentIndex].during}
            play={wording.illustration[currentIndex].play}
            svg={wording.illustration[currentIndex].svg}
            path={wording.illustration[currentIndex].path}
            text={wording.marquee} />
        }
      </Background>
      <MarqueeSection
        display={currentIndex === null ? 'show' : 'hide'}>
        <Hint>{ wording.marquee }</Hint>
      </MarqueeSection>
      <HeadingWrap>
        { wording.illustration.map((item, i) =>
          <Heading key={i}
          data-index={i}
          display={ currentIndex === i ? 'active' : 'normal' }
          status={ currentIndex === null ? 'focus' : 'blur' }
          onMouseEnter={handleHoverHeading}
          onMouseLeave={handleLeaveHeading}
          onClick={handleClickHeading}
          >{ item.title }</Heading>
        ) }
      </HeadingWrap>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
`
const HeadingWrap = styled.div`
  position: relative;
  text-align: center;
  z-index: 0;
  ${respondTo.md} {
    margin: 0 -25%;
  }
`
const Heading = styled.p`
  margin: 0;
  display: inline-block;
  margin: 0 0.6%;
  line-height: 0.9;
  font-size: 7vw;
  font-weight: 900;
  white-space: nowrap;
  color: ${colors.black};
  cursor: pointer;
  transition: all .3s ease;
  ${({ status }) => status === 'blur' && css`
    opacity: 0.5;
  `}
  ${({ display }) => display === 'active' && css`
    position: relative;
    opacity: 1;
    line-height: 0.9;
    color: ${colors.orange};
    z-index: 2;
  `}
  ${respondTo.md} {
    font-size: 11.5vw;
  }
  ${respondFrom.md} {
    &:hover {
      opacity: 1;
      line-height: 0.9;
      color: ${colors.orange};
    }
  }
`

const MarqueeSection = styled(Marquee)`
  margin-top: 20px;
  margin-bottom: 60px;
  transition: all .3s ease;
  ${({ display }) => display === 'show' && css`
    opacity: 1;
    visibility: visible;
  `}
  ${({ display }) => display === 'hide' && css`
    opacity: 0;
    visibility: hidden;
  `}
  ${respondTo.md} {
    margin-bottom: 46px;
  }
`

const Hint = styled.div`
  font-size: 36px;
  font-weight: 400;
  color: ${colors.black};
  ${respondTo.md} {
    font-size: 18px;
  }
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  display: flex;
  align-items: flex-end;
  transition: all .3s ease;
  ${({ display }) => display === 'show' && css`
    opacity: 1;
    visibility: visible;
  `}
  ${({ display }) => display === 'hide' && css`
    opacity: 0;
    visibility: hidden;
  `}
  ${({ push }) => push && css`
    position: absolute;
    top: auto;
    bottom: 0;
  `}
  .animation {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
  }
  svg, img {
    display: block;
    width: 100%;
    height: auto;
    ${respondTo.xxl} {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translate(-50%);
    }
  }
  text {
    font-size: 1.5em;
    font-family: 'Heebo';
    font-weight: 500;
    fill: ${colors.black};
  }
`


export default IllustrationSection;