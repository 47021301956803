import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Plus, Minus } from '../../components/Icons';
import { respondTo } from '../../utils/responsive';
import { colors } from '../../constants/colors';
import useConnectWallet from '../../utils/useConnectWallet';
import useMint from '../../utils/useMint';

const max = 10;

const MintBlock = ({ price_label, total_label, amount_label, price, mintText, children }) => {
  const [ qty, setQty ] = useState(1);
  const { hint } = useConnectWallet();
  const { onMint, status } = useMint();

  function handleAddQty() {
    if (qty<max){
      let currentQty = Math.max(qty+1, 1);
      setQty(currentQty);
    }
  }

  function handleDecreaseQty() {
    let currentQty = Math.max(qty-1, 1);
    setQty(currentQty);
  }
  
  function handleSetQty(value) {
    if (value<=max){
      setQty(value);
    } else {
      setQty(max);
    }
  }
  
  async function handleClickMintButton() {
    onMint(qty);
  }
  
  return (
    <Root>
      <li className="input">
        <label>{ amount_label }</label>
        <QtySelector qty={qty} 
          onPlusClick={handleAddQty}
          onMinusClick={handleDecreaseQty}
          onQtyInput={handleSetQty}
        />
      </li>
      <li className={`price ${children && '-children'}`}>
        <label>{ price_label }</label>
        <p>
          <span>{price} eth</span>
          { children && <span className="children">{ children }</span> }
        </p>
        
      </li>
      <li className="total">
        <label>{ total_label }</label>
        <p>{parseFloat(price * qty).toFixed(1)} eth</p>
      </li>
      <MintButton onClick={handleClickMintButton}>{ mintText }</MintButton>
      <li></li>
      <div className="hint">{ status || hint }</div>
    </Root>
  )
}


const QtySelector = ({ qty, onPlusClick, onMinusClick, onQtyInput }) => {

  return (
    <Selector>
      <QtyButton disabled={qty <= 1} onClick={onMinusClick}><Minus /></QtyButton>
      <div className="qty">{ qty }</div>
      <QtyButton disabled={qty === max} onClick={onPlusClick}><Plus /></QtyButton>
    </Selector>
  )
}

const Root = styled.ul`
  margin: 0 auto;
  width: 380px;
  ${respondTo.md} {
    width: 226px;
  }
  > li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    ${respondTo.md} {
      margin-bottom: 12px;
      &.-children {
        padding-bottom: 28px;
      }
    }
    label {
      font-size: 24px;
      line-height: 1;
      ${respondTo.md} {
        font-size: 18px;
      }
    }
    .children {
      ${respondTo.md} {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    p {
      font-size: 24px;
      line-height: 1;
      ${respondTo.md} {
        font-size: 18px;
        text-align: right;
      }
    }
  }
`

const Selector = styled.div`
  display: inline-flex;
  align-items: center;
  .qty {
    border: 0;
    margin: 0;
    padding: 0;
    width: 44px;
    line-height: 1;
    font-size: 24px;
    font-weight: 400;
    background: transparent;
    color: ${colors.trueBlack};
    text-align: center;
    ${respondTo.md} {
      font-size: 18px;
      height: 18px;
      width: 30px;
    }
  }
`

const QtyButton = styled.button`
  border: 0;
  margin: 0 2px;
  padding: 0;
  font-size: 12px;
  line-height: 1;
  background: transparent;
  color: #000;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`

const MintButton = styled.button`
  border: 0;
  border-radius: 5px;
  margin-top: 52px;
  padding: 10px 24px;
  width: 100%;
  height: 60px;
  background: ${colors.orange};
  font-weight: 500;
  font-size: 28px;
  line-height: 1;
  color: ${colors.black};
  transition: background .3s ease;
  &:hover {
    background: ${colors.lightOrange};
  }
  ${respondTo.md} {
    margin-top: 40px;
    height: 44px;
    font-size: 22px;
  }  
`

export default MintBlock