import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../utils/responsive';
import useWording from '../../utils/useWording';
import MintBlock from '../../components/MintBlock';
import AmountCount from './AmountCount';
import { _w } from '../../utils/wordingSystem';

const PublicMintSection = ({...props}) => {
  const wording = useWording('homepage');
  const { price } = _w('setting.public_mint')
  
  return (
    <Root {...props}>
      <Title>{ wording.public_mint_title }</Title>
      <AmountCount />
      <MintBlock
        price_label={wording.price_label}
        total_label={wording.total_label}
        amount_label={wording.amount_label}
        price={price}
        mintText={wording.mint_submit} />
    </Root>
  )
}


const Root = styled.div`
  margin-bottom: 90px;
  ${respondTo.md} {
    padding-top: 160px;
    margin-bottom: 90px;
  }
`

const Title = styled.div`
  margin-bottom: 44px;
  line-height: 1;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  ${respondTo.md} {
    font-size: 24px;
  }
`
const Description = styled.div`
  margin-bottom: 24px;
  line-height: 1;
  font-weight: 400;
  font-size: 72px;
  text-align: center;
  ${respondTo.md} {
    font-size: 40px;
  }
`

export default PublicMintSection;