import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import useWording from '../../utils/useWording';
import { _w } from '../../utils/wordingSystem';

const CountdownSection = ({ onFinish, ...props}) => {
  const timerRef = useRef(null);
  const endTimeRef = useRef(null);
  const [ count, setCount ] = useState(null);
  const { datetime_title } = useWording('homepage');
  const { datetime } = _w('setting.countdown');
  
  useEffect(() => {
    const currentTime = new Date().getTime();
    const endTime = new Date(datetime).getTime();
    endTimeRef.current = endTime;
    timerRef.current = setInterval(handleUpdateTime, 1000);
    if (currentTime > endTime) {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [])

  function handleUpdateTime() {
    const now = new Date().getTime();
    const currentTime = endTimeRef.current - now;
    setCount(currentTime);
    if (currentTime <= 0) {
      onFinish && onFinish();
    }
  }
  
  return (
    <Root {...props}>
      <Head>
        <p>{ datetime_title }</p>
      </Head>
      <Timer>
        { count ? <Clock unit="hours" count={count} /> : '00' }
        <span>:</span>
        { count ? <Clock unit="minutes" count={count} /> : '00' }
        <span>:</span>
        { count ? <Clock unit="seconds" count={count} /> : '00' }
      </Timer>
    </Root>
  )
}

const Clock = ({ count, unit }) => {
  function numberTransfer(num) {
    const length = (num + '').length;
    let str = Math.max(num, 0);
    if (length === 1) {
      str = `0${num}`;
    }
    return str;
  }
  
  return (
    <ClockRoot>
      <div className="counter">
        { unit === 'hours' && numberTransfer(
          Math.max(
            Math.floor((count % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))) + (Math.floor(count / (1000 * 60 * 60 * 24)) * 24),
            0
          )
        }
        { unit === 'minutes' && numberTransfer(Math.floor((count % (1000 * 60 * 60)) / (1000 * 60))) }
        { unit === 'seconds' && numberTransfer(Math.floor((count % (1000 * 60)) / 1000)) }
      </div>
    </ClockRoot>
  )
}

const Root = styled.div`
  margin-bottom: 240px;
  ${respondTo.md} {
    padding-top: 160px;
    margin-bottom: 90px;
  }
`

const Head = styled.div`
  margin-bottom: 36px;
  color: ${colors.trueBlack};
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  ${respondTo.md} {
    margin-bottom: 48px;
    font-size: 24px;
  }
`

const Timer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 144px;
  line-height: 1;
  font-weight: 700;
  color: ${colors.trueBlack};
  ${respondTo.md} {
    font-size: 50px;
  }
  span {
    display: inline-block;
    margin: 0 36px;
    ${respondTo.md} {
      margin: 0 18px;
    }
  }
`

const ClockRoot = styled.div`
  text-align: center;
  box-sizing: border-box;
`

export default CountdownSection;