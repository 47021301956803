import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import Web3 from 'web3';
import { ethers } from "ethers";
import dotenv from 'dotenv'

const web3 = new Web3(Web3.givenProvider);
const contractABI = require("../../contract-abi.json");
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
const NFTContract = new web3.eth.Contract(contractABI, contractAddress);

const AmountCount = ({...props}) => {
  const [ totalSupply, setTotalSupply ] = useState(2200);

  useEffect(() => {
    NFTContract.events.mintEvent({
        filter:{},
        fromBlock: 0,
      }, function(error, event){/*console.log(event);*/}).on("data", function(event) {
        setTotalSupply(event.returnValues.totalSupply); 
      }).on('error', console.error);
    }, [])  


  return (
    <Root {...props}>
      { 2200 - totalSupply} / 2200
    </Root>
  )
}

const Root = styled.div`
  margin-bottom: 24px;
  line-height: 1;
  font-weight: 400;
  font-size: 72px;
  text-align: center;
  ${respondTo.md} {
    font-size: 40px;
  }
`

export default AmountCount;