import { createSlice } from "@reduxjs/toolkit";

const bottomMenu = createSlice({
    name: 'bottomMenu',
    initialState: {
      open: false
    },
    reducers: {
      setOpen: (state) =>{
        state.open = true;
      },
      setClose: (state) =>{
        state.open = false;
      },
      setToggle: (state) =>{
        state.open = !state.open;
      }
    }
});

export default bottomMenu;
