import React, { useRef } from 'react'
import styled, { css, keyframes } from 'styled-components';
import { respondTo } from '../../utils/responsive';


const Marquee = ({ direction, during=10, children, ...props }) => {
  const marqueeRef = useRef(null);
  return (
    <Root {...props}>
      <Main direction={direction} during={during} ref={marqueeRef}>
        <div>{ children }</div>
        <div>{ children }</div>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  height: 60px;
  overflow: hidden;
  ${respondTo.md} {
    height: 56px;
  }
`

const move = keyframes`
  from { transform: translateX(0) }
  to { transform: translateX(-50%) }
`

const moveReverse = keyframes`
  from { transform: translateX(-50%) }
  to { transform: translateX(0%) }
`

const Main = styled.div`
  position: absolute;
  left: 0;
  top: 10px;
  white-space: nowrap;
  overflow: hidden;
  animation: ${move} ${({ during }) => during}s linear infinite;
  will-change: transform;
  > div {
    display: inline-block;
    width: 100vw;
  }
  ${({ direction }) => direction === 'reverse' && css`
    transform: translateX(-50%);
    animation: ${moveReverse} ${({ during }) => during}s linear infinite;
  `}
`

export default Marquee;
  