import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { useWordingLoader } from './utils/wordingSystem';

import appStore from './store/app';
import walletStatus from './store/walletStatus';
import language from './store/language';
import bottomMenu from './store/bottomMenu';

import GlobalStyle from './components/GlobalStyle';
import AutoScrollHelper from './components/AutoScrollHelper';
import ScrollToTopHelper from './components/ScrollToTopHelper';
import Bottom from './components/Bottom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import PassAndFeastPage from './pages/PassAndFeastPage';
import IngredientsAndCuisine from './pages/IngredientsAndCuisine';
import RealityAndVirtual from './pages/RealityAndVirtual';
import Header from './components/Header';
import styled from 'styled-components';
import LanguageHelper from './components/LanguageHelper';

const reducer = combineReducers({
  app: appStore.reducer,
  language: language.reducer,
  bottomMenu: bottomMenu.reducer,
  walletStatus: walletStatus.reducer,
});

const store = createStore(reducer);


const App = ({ wording, Router = BrowserRouter }) => {
  const wordingLoaded = useWordingLoader(wording ?? '/wordings/main.json?v=202204061540');

  
  return (
    <Provider store={store}>
      <Helmet>
        <title>PlateForm by Unname Token</title>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" rel="stylesheet"></link>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" rel="stylesheet"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Heebo:wght@100;300;400;500;600;700;900&display=swap&family=Noto+Sans+TC:wght@500;700;900" rel="stylesheet"></link>
      </Helmet>
      <GlobalStyle/>
      <LanguageHelper />
      <Router>
        { !wordingLoaded &&
          <Cover></Cover>
        }
        { wordingLoaded &&
          <>
            <ScrollToTopHelper />
            <AutoScrollHelper />
            <Header />
            <Switch>
              <Route path="/" exact={true} component={HomePage} />
              <Route path="/about" exact={true} component={AboutPage} />
              <Route path="/ingredients-and-cuisine" exact={true} component={IngredientsAndCuisine} />
              <Route path="/pass-and-feast" exact={true} component={PassAndFeastPage} />
              <Route path="/reality-and-virtual" exact={true} component={RealityAndVirtual} />
              
              <Route path="/zh-TW" exact={true} component={HomePage} />
              <Route path="/zh-TW/about" exact={true} component={AboutPage} />
              <Route path="/zh-TW/ingredients-and-cuisine" exact={true} component={IngredientsAndCuisine} />
              <Route path="/zh-TW/pass-and-feast" exact={true} component={PassAndFeastPage} />
              <Route path="/zh-TW/reality-and-virtual" exact={true} component={RealityAndVirtual} />
            </Switch>
            <Bottom />
          </>
        }
      </Router>
    </Provider>
  );
}

const Cover = styled.div`
`

export default App;
