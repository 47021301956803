import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import Link from '../CustomLink';
import LinksList from '../LinksList';
import SocialsList from '../SocialsList';
import LanguageButton from '../LanguageButton';
import { respondTo } from '../../utils/responsive';
import { colors } from '../../constants/colors';
import useWording from '../../utils/useWording';
import { MenuBurger } from '../Icons';
import bottomMenu from '../../store/bottomMenu';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const wording = useWording('header');
  const headerRef = useRef(null);
  const prevPageYOffset = useRef(0);
  const [ open, setOpen ] = useState(true);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);    
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, [])

  function handleWindowScroll() {
    const pageYOffset = Math.max(window.pageYOffset, 0);
    const delta = pageYOffset - prevPageYOffset.current;

    if (delta < 0) setOpen(true);
    if (delta > 0) setOpen(false);

    prevPageYOffset.current = pageYOffset;
  }

  
  function handleClickMenuButton() {
    dispatch(bottomMenu.actions.setToggle())

  }

  function matchWithHomepage(name) {
    const routes = ['/', '/zh-TW']
    return routes.includes(name);
  }

  return (
    <Root ref={headerRef}
      display={open ? 'show' : 'hide'}
      mobileBackground={matchWithHomepage(pathname) ? 'hide' : 'show'} >
      <Wrapper>
        <Link className="logo" to="/">
          <img src="/images/header-logo.svg" alt="" />
        </Link>
        <Side>
          <MenuWrapper className="menu">
            <LinksList className="link-list" data={wording.links} />
            <Buttonbar>
              <SocialsList data={wording.socials} />
              <LanguageButton className="lang" />
            </Buttonbar>
          </MenuWrapper>
          <MenuButton onClick={handleClickMenuButton}><MenuBurger /></MenuButton>
        </Side>
      </Wrapper>
    </Root>
  )
}

const Root = styled.header`
  padding: 36px 40px 22px 40px;
  box-sizing: border-box;
  ${respondTo.md} {
    position: fixed;
    top: 0;
    left: 0;
    padding: 24px 20px 24px 20px;
    width: 100%;
    z-index: 3;
    transition: transform .3s ease;
    ${({ mobileBackground }) => mobileBackground === 'show' && css`
      background-color: ${colors.background};
    `}
    ${({ display }) => display === 'show' && css`
      transform: translateY(0);
    `}
    ${({ display }) => display === 'hide' && css`
      transform: translateY(-100%);
    `}
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  .logo {
    position: relative;
    display: flex;
    align-items: center;
    width: 138px;
    z-index: 3;
    ${respondTo.md} {
      width: 110px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .menu {
    height: 100%;
    box-sizing: border-box;
    ${respondTo.md} {
      height: auto;
    }
  }
`

const Side = styled.div`
  position: relative;
`

const MenuWrapper = styled.div`
  ${ respondTo.md } {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    position: absolute;
    border-radius: 8px;
    top: 100%;
    right: 40px;
    padding: 12px 30px;
    background: hsla(0, 0%, 30%, .66);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transform: translateY(12px);
    transition: all .3s ease;
    box-sizing: border-box;
    ${({open}) => open && css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0%);
    `}
  }
`
const Buttonbar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  .lang {
    margin-left: 8px;
  }
`

const MenuButton = styled.button`
  display: none;
  border: 0;
  background: transparent;
  color: ${colors.black};
  ${ respondTo.md } {
    display: block;
  }
`

export default Header;
