import useSWR from 'swr';

function fetchSvgData(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
    .then(res => resolve(res.text()))
    .catch(err => reject(err))
  })
}

function useFetchSvgData(source = '/svg/BLACK_GARLIC.svg') {
  const fetcher = async() => await fetchSvgData(source);
  return useSWR(source, fetcher, { dedupingInterval: 999999 });
}


export { useFetchSvgData };