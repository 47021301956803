import React, { useEffect, useState } from 'react';
import { useFetchSvgData } from '../../utils/fetchData';

const SVGTextAnimation = ({ svg, path, play, text='Text laid out along a path.', during=10, ...props }) => {
  const { data: svgContent } = useFetchSvgData(svg);
  const { data: pathContent } = useFetchSvgData(path);
  const [ resultSvg, setResultSvg ] = useState(null);
  const [ isInsertText, setIsInsertText ] = useState(false);

  useEffect(() => {
    if (svgContent && pathContent && !isInsertText) {
      handleInsertText();
    }
  }, [svgContent, pathContent])

  function handleInsertText() {
    const wrap = document.createElement('div');
    wrap.innerHTML = svgContent;
    const svg = wrap.querySelector('svg');

    // Path
    const pathElement = getPath(pathContent);
    pathElement.id = 'animationTextPath';
    pathElement.setAttribute = 'animationTextPath';
    svg.appendChild(pathElement);

    // Loop Text
    if (play === 'loop') {
      const textWrap = document.createElement('g');
      const textElements = text.split('');
      textElements.reverse().forEach((char, i) => {
        const textElement = document.createElement('text');
        textElement.setAttribute('fill', '#000');
        textElement.setAttribute('text-anchor', 'middle');
        const template = `
          ${char}
          <animateMotion dur="${during}s" repeatCount="indefinite" rotate="auto" begin="${0.05 * i * 10/during}s">
            <mpath xlink:href="#animationTextPath"/>
          </animateMotion>
        `;
        textElement.innerHTML = template;
        textWrap.appendChild(textElement);
        svg.appendChild(textWrap);
      })
    }
    else {
      const textElement = document.createElement('text');
      textElement.setAttribute('fill', '#000');
      const template = `
        <textpath xlink:href="#animationTextPath">
          ${text}
          <animate attributeName="startOffset" from="0%" to ="100%" begin="0s" dur="${during}s" begin="theSVG.click;theSVG.click+3" repeatCount="indefinite" keyTimes="0;1" calcMode="linear" />
        </textpath>`;
      textElement.innerHTML = template;
      svg.appendChild(textElement);
    }


    setResultSvg(wrap.innerHTML);
    setIsInsertText(true);
  }

  function getPath(dom) {
    const wrap = document.createElement('div');
    const path = document.createElement('path');

    wrap.innerHTML = dom;
    path.setAttribute('fill', 'none');
    const d = wrap.querySelector('path').getAttribute('d');
    
    path.setAttribute('d', d);
    return path;
  }
  
  
  return (
    <>
      { 
        (resultSvg && isInsertText) ?
        <div {...props} dangerouslySetInnerHTML={{ __html: resultSvg }} />
        :
        <img src={svg} alt="" />
      }
    </>
  )
}

export default SVGTextAnimation;