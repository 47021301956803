import { useEffect, useRef, useState } from 'react';

const useCountdown = (datetime) => {
  const timerRef = useRef(null);
  const endTimeRef = useRef(null);
  const [ count, setCount ] = useState(null);

  useEffect(() => {
    if (datetime) {
      const currentTime = new Date().getTime();
      const endTime = typeof datetime === 'number' ? datetime : new Date(datetime).getTime();
      endTimeRef.current = endTime;
      timerRef.current = setInterval(handleUpdateTime, 1000);
      if (currentTime > endTime) {
        clearInterval(timerRef.current)
      }
      return () => clearInterval(timerRef.current);
    }
  }, [datetime])

  function handleUpdateTime() {
    const now = new Date().getTime();
    setCount(endTimeRef.current - now);
  }
  
  return {
    hours: Math.max(
      Math.floor((count % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + (Math.floor(count / (1000 * 60 * 60 * 24)) * 24),
      0
    ),
    minutes: Math.floor((count % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((count % (1000 * 60)) / 1000),
    isFinish: count !== null && count <= 0
  }
}


export default useCountdown;