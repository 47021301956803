import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondTo } from '../../utils/responsive';
import { colors } from '../../constants/colors';
import Link from '../CustomLink';
import Text from '../Text';

const LinksList = ({ data, onLinkClick, ...props }) => {
  const { pathname } = useLocation();

  return (
    <List {...props}>
      { data.map((link, i) =>
        <React.Fragment key={i}>
        { link.to &&
          <LinkItem
            onClick={onLinkClick}
            display={pathname.includes(link.to) ? 'highlight' : ''}
            to={link.to}
            show={link.hide}>
            { link.title }
          </LinkItem>
        }
        {
          link.href &&
          <LinkItem as="a" href={link.href} target="_blank" show={link.hide}>
            { link.title }
          </LinkItem>
        }
        </React.Fragment>
      ) }
    </List>
  )
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${respondTo.md} {
    justify-content: center;
    align-items: center;
  }
`

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  border: 0;
  height: 100%;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.black};
  transition: color .3s ease;
  &:hover {
    color: ${colors.orange};
  }
  ${respondTo.md} {
    display: block;
    margin: 0;
    padding: 4px 0;
    height: auto;
    font-size: 18px;
    text-align: center;
  }

  ${({ display }) => display === 'highlight' && css`
    color: ${colors.orange};
  `}

  ${({ show }) => show === 'desktop' && css`
    display: none;
  `}

  ${ respondTo.md } {
    display: block;
    ${({ show }) => show === 'desktop' && css`
      display: block;
    `}
  }
`

export default LinksList;