const colors = {
  background: "#D8D7D0",
  gray: "#333",
  orange: "#FF7A00",
  lightOrange: "#FF9900",
  gray: "#525151",
  black: "#111",
  trueBlack: "#000",
  white: "#FFF"
}

export { colors };