import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import useWording from '../../utils/useWording';
import { respondTo } from '../../utils/responsive';
import { DiscordLogo, LineLogo } from '../../components/Icons';
import Text from '../../components/Text';

const AboutPage = () => {
  const wording = useWording('about');

  return (
    <Root>
      <Title>{ wording.unname.title }</Title>
      <Content>{ wording.unname.content }</Content>
      <ButtonBar>
        <Button href={wording.line_link} target="_blank">
          <LineLogo />
        </Button>
        <Button href={wording.discord_link} target="_blank">
          <DiscordLogo />
        </Button>
      </ButtonBar>
      <Title>{ wording.platform.title }</Title>
      <Content>{ wording.platform.content }</Content>
    </Root>
  )
}

const Root = styled.div`
  padding: 0 40px;
  padding-bottom: 90px;
  ${respondTo.md} {
    padding: 0 20px;
    padding-top: 85px;
    padding-bottom: 60px;
  }
`

const Title = styled(Text)`
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 700;
  ${respondTo.md} {
    font-size: 22px;
  }
`

const Content = styled(Text)`
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 400;
  ${respondTo.md} {
    font-size: 16px;
  }
`

const ButtonBar = styled.div`
  display: flex;
`

const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  margin-right: 12px;
  align-items: center;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  background: ${colors.black};
  color: ${colors.black};
  transition: all .3s ease;
  ${respondTo.md} {
    margin-right: 10px;
    width: 32px;
    height: 32px;
  }
  &:hover {
    color: ${colors.orange};
    background: ${colors.orange};
  }
  svg {
    width: 30px;
    ${respondTo.md} {
      width: 21px;
    }
  }
  span {
    display: inline-block;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 500;
    ${respondTo.md} {
      font-size: 14px;
    }
  }
`

export default AboutPage;