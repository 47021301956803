import React, { useEffect, useMemo, useState } from 'react';
import { parse } from 'query-string';
import styled from 'styled-components';
import { _w } from '../../utils/wordingSystem';
import { respondTo } from '../../utils/responsive';
import CountdownSection from './CountdownSection';
import PublicMintSection from './PublicMintSection';
import DutchAuctionSection from './DutchAuctionSection';
import IllustrationSection from './IllustrationSection';
import { css } from 'styled-components';

const HomePage = () => {
  const { countdown, public_mint, dutch_auction } = _w('setting');
  const [ nextSection, setNextSection ] = useState(false);

  useEffect(() => {
    if (detectDateTimeDuration(public_mint.duration)||
    detectDateTimeDuration(dutch_auction.duration)
    ) {
      setNextSection(true);
    }
  }, [])

  function detectDemoSection(name) {
    const { demo } = parse(window.location.search);
    return demo && demo === name;
  }

  function detectDateTimeDuration({ start, end }) {
    const startAt =  new Date(start).getTime();
    const endAt =  new Date(end).getTime();
    const current =  new Date().getTime();

    return current > startAt && current < endAt;
  }

  const isCountdownShow = useMemo(() => {
    return (!nextSection && detectDateTimeDuration(countdown.duration)) ||
    detectDemoSection('countdown')
  }, [nextSection])

  const isPublicMintShow = useMemo(() => {
    return (detectDateTimeDuration(public_mint.duration) && nextSection) ||
    detectDemoSection('public_mint')
  }, [nextSection])

  const isDutchAuctionMintShow = useMemo(() => {
    return (detectDateTimeDuration(dutch_auction.duration) && nextSection) ||
    detectDemoSection('dutch_auction')
  }, [nextSection])

  function handleTriggerNextStep() {
    setNextSection(true);
  }
  
  return (
    <Root fixed={!isCountdownShow && !isPublicMintShow && !isDutchAuctionMintShow}>
      { isCountdownShow && <CountdownSection onFinish={handleTriggerNextStep} /> }
      { isPublicMintShow && <PublicMintSection /> }
      { isDutchAuctionMintShow && <DutchAuctionSection /> }
      <IllustrationSection className="illustration" isPush={isCountdownShow || isPublicMintShow || isDutchAuctionMintShow} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  ${({ fixed }) => fixed && css`
    height: calc(100vh - 200px);
    ${respondTo.md} {
      padding-top: 120px;
      height: 100vh;
    }
  `}
  .illustration {
    ${respondTo.md} {
      padding-bottom: 80px;
    }
    ${({ fixed }) => fixed && css`
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    `}
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border: none;
    }
  }
`
export default HomePage;