import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';
import useWording from '../../utils/useWording';
import { respondFrom, respondTo } from '../../utils/responsive';
import Container from '../../components/Container';
import Text from '../../components/Text';
import CustomLink from '../../components/CustomLink';
import { _w } from '../../utils/wordingSystem';
import ModalBox from '../../components/ModalBox';
import { keyframes } from 'styled-components';

const PassAndFeastPage = () => {
  const wording = useWording('pass_and_feast');
  const { public_mint, dutch_auction } = _w('setting');
  const [ currentIndex, setCurrentIndex ] = useState(null);
  const [ isHighlight, setIsHighlight ] = useState(false);
  const [ isHintShow, setIsHintShow ] = useState(false);

  function handleClickNote() {
    setIsHighlight(true);
  }
  function handleClickCover() {
    setIsHighlight(false);
  }

  function detectDateTimeDuration({ start, end }) {
    const startAt =  new Date(start).getTime();
    const endAt =  new Date(end).getTime();
    const current =  new Date().getTime();

    return current > startAt && current < endAt;
  }

  const isMintActive = useMemo(() => {
    return detectDateTimeDuration(public_mint.duration) ||
    detectDateTimeDuration(dutch_auction.duration)
  }, [])

  function handleCloseModal() {
    setCurrentIndex(null)
  }

  function handleClickItem(e) {
    const index = Number(e.currentTarget.dataset.index);
    setCurrentIndex(index);
  }

  function handleClickMint(e, active) {
    if (!active) e.preventDefault();
    if (!isHintShow) {
      setIsHintShow(true);
      setTimeout(() => {
        setIsHintShow(false);
      }, 3000);
    }
  }
  
  return (
    <Root>
      { !isMintActive &&
        <Hint display={isHintShow ? 'show': 'hide'}>
          <div className="msg">{ wording.coming_soon }</div>
        </Hint>
      }
      <ModalBox open={currentIndex !== null} onClose={handleCloseModal}>
        { currentIndex !== null &&
          <ModalContent>
            <Text className="title">
              { wording.list[currentIndex].title.replace(/\r|\n|\r\n/g, '') }
            </Text>
            <Text className="content">
              { wording.list[currentIndex].content }
            </Text>
          </ModalContent>
        }
      </ModalBox>
      <Title>{ wording.title }</Title>
      <Description dangerouslySetInnerHTML={{ __html: wording.description }} />
      <Buttonbar>
        <MintButton
          to="/"
          display={isMintActive ? 'enable' : 'disable'}
          onClick={(e) => handleClickMint(e, isMintActive)}
        >{ wording.mint }</MintButton>
        <div className="note" onClick={handleClickNote}>{ wording.note }</div>
      </Buttonbar>
      <Cover display={ isHighlight ? 'show' : 'hide'} onClick={handleClickCover} />
      <List>
        { wording.list.map((item, i) => 
          <li key={i}>
            <NFTItem
              display={ item.type === 'heading' ? 'active' : '' }
              data-index={i}
              data={item}
              onClick={handleClickItem} />
          </li>
        ) }
      </List>
    </Root>
  )
}

const NFTItem = ({ data, onClick, ...props }) => {
  const { type, title, image } = data;
  const [ isActive, setIsActive ] = useState(false);
  const [ isLoaded, setIsLoaded ] = useState(false);

  function handleClickItem(e) {
    setIsActive(!isActive);
    (type ==='heading' && onClick) && onClick(e);
  }

  useEffect(() => {
    if (image) {
      const imageElement = new Image();
      imageElement.src = image;
      imageElement.onload = function() {
        setIsLoaded(true);
      }
    }
  }, [])
  
  return (
    <NFTItemRoot
      type={type}
      focusDisplay={isActive ? 'active' : 'normal'}
      onClick={handleClickItem}
      {...props}>
      { type === 'icon' && 
        <div className="icon">
          <img src={isLoaded ? image : '/images/nft-placehold.svg'} />
          <Text as="p">{ title }</Text>
        </div>
      }
      { type === 'heading' && <Text className="text">{ title }</Text> }
    </NFTItemRoot>
  )
}

const Root = styled(Container)`
  padding-bottom: 200px;
  box-sizing: border-box;
  ${respondTo.md} {
    padding-top: 85px;
  }
`
const Title = styled(Text)`
  margin-bottom: 20px;
  color: ${colors.black};
  font-size: 36px;
  font-weight: 700;
  ${respondTo.md} {
    font-size: 22px;
    white-space: nowrap;
  }
`
const Description = styled(Text)`
  font-size: 18px;
  color: ${colors.block};
  white-space: break-spaces;
  ${respondTo.md} {
    font-size: 16px;
  }
  strong {
    font-size: 22px;
    font-weight: 700;
  }
`
const Buttonbar = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  > .note {
    margin-left: 20px;
    font-size: 16px;
    color: ${colors.trueBlack};
    text-decoration: underline;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      color: ${colors.orange};
    }
    ${respondTo.md} {
      font-size: 14px;
    }
  }
`

const MintButton = styled(CustomLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  padding: 8px 20px;
  width: 150px;
  height: 36px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: ${colors.black};
  background-color: ${colors.orange};
  box-sizing: border-box;
  transition: all .3s ease;
  ${respondTo.md} {
    width: 150px;
    height: 36px;
    font-size: 14px;
  }
  ${({ display }) => display === 'enable' && css`
    &:hover {
      background-color: ${colors.lightOrange};
    }
  `}
  ${({ display }) => display === 'disable' && css`
    background-color: #C7C7C7;
    color: ${colors.black};
  `}
`

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.black};
  z-index: 2;
  transition: all .3s ease;
  ${({ display }) => display === 'show' && css`
    opacity: 0.5;
    visibility: visible;
  `}
  ${({ display }) => display === 'hide' && css`
    opacity: 0;
    visibility: hidden;
  `}
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
  margin-top: 40px;
  > li {
    padding: 6px;
    width: 25%;
    box-sizing: border-box;
    ${respondTo.lg} {
      width: 33.33%;
    }
    ${respondTo.md} {
      width: 50%;
    }
  }
`

const NFTItemRoot = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 100%;
  ${({ type }) => type === 'heading' && css`
    background-color: ${colors.orange};
    cursor: pointer;
    transition: background-color .3s ease;
    ${respondFrom.md} {
      &:hover {
        background-color: ${colors.lightOrange};
      }
    }
  `}
  ${({ type }) => type === 'icon' && css`
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid ${colors.black};
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      z-index: 1;
    }
  `}
  ${({ display }) => display === 'active' && css`
    z-index: 2;
  `}
  > .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    p {
      position: absolute;
      left: 0;
      top: 0;
      padding: 10px;
      width: 100%;
      height: 100%;
      background: ${colors.black};
      color: ${colors.white};
      font-size: 52px;
      font-weight: 700;
      white-space: break-spaces;
      box-sizing: border-box;
      z-index: 1;
      transition: opacity .3s ease;
      opacity: 0;
      ${respondTo.md} {
        font-size: 7vw;
      }
    }
    ${respondFrom.md} {
      &:hover p {
        opacity: 1;
      }
    }
    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  > .text {
    padding: 10px;
    font-size: 52px;
    font-weight: 700;
    white-space: break-spaces;
    ${respondTo.md} {
      font-size: 7vw;
    }
  }
  ${({ focusDisplay }) => focusDisplay === 'active' && css`
    ${respondTo.md} {
      > .icon > p { opacity: 1; }
    }
  `}
`

const ModalContent = styled.div`
  padding: 126px 148px;
  width: 900px;
  max-width: 90vw;
  max-height: 90vh;
  background: ${colors.background};
  box-sizing: border-box;
  overflow-y: auto;
  ${respondTo.md} {
    display: block;
    padding: 28px 20px;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
  > .title {
    margin-bottom: 18px;
    font-size: 36px;
    font-weight: 700;
    ${respondTo.md} {
      font-size: 22px;
    }

  }
  > .content {
    font-size: 18px;
    ${respondTo.md} {
      font-size: 16px;
    }
  }
`

const hintAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`

const Hint = styled.div`
  position: fixed;
  bottom: 80px;
  left: 0;
  width: 100%;
  z-index: 4;
  animation: ${hintAnimation} 3s ease forwards;
  text-align: center;
  ${({ display }) => display === 'show' && css`
    display: block;
  `}
  ${({ display }) => display === 'hide' && css`
    display: none;
  `}
  > .msg {
    display: inline-block;
    margin: 0 auto;
    border-radius: 8px;
    padding: 8px 40px;
    background: ${colors.orange};
    font-size: 18px;
    color: ${colors.white};
    ${respondTo.md} {
      font-size: 16px;
    }
  }
`

export default PassAndFeastPage;