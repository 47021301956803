import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../utils/responsive';
import { Twitter, Facebook, Discord, Opensea, Instagram, Line } from '../../components/Icons';
import { colors } from '../../constants/colors';

const SocialItem = ({icon, children, ...props}) => {
  return (
    <Item {...props}>
      { children }
      { icon === 'Twitter' && <Twitter /> }
      { icon === 'Facebook' && <Facebook /> }
      { icon === 'Discord' && <Discord /> }
      { icon === 'Opensea' && <Opensea /> }
      { icon === 'Instagram' && <Instagram /> }
      { icon === 'Line' && <Line /> }
    </Item>
  )
}

const Item = styled.a`
  display: flex;
  align-items: center;
  width: 30px;
  transition: all .3s ease;
  color: ${colors.black};
  ${respondTo.md} {
    width: 22px;
  }
  svg, img {
    display: block;
    width: 100%;
    height: auto;
  }
  &:hover {
    color: ${colors.orange};
  }
`


export default SocialItem;