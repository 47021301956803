import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../utils/responsive';
import useWording from '../../utils/useWording';
import MintBlock from '../../components/MintBlock';
import AmountCount from './AmountCount';
import { _w } from '../../utils/wordingSystem';
import useCountdown from '../../utils/useCountdown';
import { colors } from '../../constants/colors';

const DutchAuctionSection = ({...props}) => {
  const wording = useWording('homepage');
  const { duration, start_price, time_step, price_step, end_price } = _w('setting.dutch_auction');
  const [ step, setStep ] = useState(null);
  const [ isDutchEnd, setIsDutchEnd ] = useState(false);

  const currentCountdowns = useMemo(() => {
    const startTime = new Date(duration.start).getTime();
    const endTime = new Date(duration.end).getTime();
    const timeStep = time_step * 60 * 1000;
    const countdowns = [startTime];
    let currentCountdown = startTime;

    while (currentCountdown + timeStep <= endTime) {
      currentCountdown += timeStep;
      countdowns.push(currentCountdown);
    };

    return countdowns;
  }, [duration, time_step]);

  const { hours, minutes, seconds, isFinish } = useCountdown(currentCountdowns[step]);

  useEffect(() => {
    updateCurrentStep();
  }, [currentCountdowns])

  useEffect(() => {
    if (isFinish) {
      updateCurrentStep();
    }
  }, [isFinish])
  
  const currentPrice = useMemo(() => {
    const discount = price_step * (step-1);
    const price = Math.max(start_price - discount, end_price);
    const count = price_step.toString().split(".")[1].length;
    return Number(price.toFixed(count));
  }, [step])

  function updateCurrentStep() {
    const currentTime = new Date().getTime();
    let result = 0;
    for(let i = 0; i < currentCountdowns.length; i++) {
      if (currentTime >= currentCountdowns[i]) {
        result = i+1;
      }
    }
    if (result >= currentCountdowns.length) {
      setIsDutchEnd(true);
    }
    else {
      setStep(result);
    }
  }

  function numberTransfer(num) {
    const length = num.toString().length;
    let str = Math.max(num, 0);
    if (length === 1) {
      str = `0${num}`;
    }
    return str;
  }
  
  return (
    <>
    { !isDutchEnd &&
      <Root {...props}>
        <Title>{ wording.dutch_auction_title }</Title>
        <AmountCount />
        <MintBlock
          price_label={wording.price_label}
          total_label={wording.total_label}
          amount_label={wording.amount_label}
          price={currentPrice}
          mintText={wording.mint_submit}>
            <Timer>
              <span>{ hours ? numberTransfer(hours) : '00' }</span>
              <span>:</span>
              <span>{ minutes ? numberTransfer(minutes) : '00' }</span>
              <span>:</span>
              <span>{ seconds ? numberTransfer(seconds)  : '00' }</span>
            </Timer>
          </MintBlock>
      </Root>
    }
    </>
  )
}

const Root = styled.div`
  margin-bottom: 90px;
  ${respondTo.md} {
    padding-top: 160px;
    margin-bottom: 90px;
  }
`

const Title = styled.div`
  margin-bottom: 44px;
  line-height: 1.2;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  ${respondTo.md} {
    font-size: 24px;
  }
`

const Timer = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: ${colors.white};
  padding: 6px 8px;
  border-radius: 4px;
  background-color: #333;
  transform: translateY(-50%);
  ${respondTo.md} {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    margin-top: 4px;
  }
  p {
    display: inline-block;
  }
  span {
    display: inline-block;
  }
`

export default DutchAuctionSection;