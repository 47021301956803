import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import LinksList from '../LinksList';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import useWording from '../../utils/useWording';
import SocialsList from '../SocialsList';
import LanguageButton from '../LanguageButton';
import bottomMenu from '../../store/bottomMenu';
import { lockWindow } from '../../utils/methods';
import useConnectWallet from '../../utils/useConnectWallet';

const Bottom = () => {
  const wording = useWording('bottom');
  const { open } = useSelector(state => state.bottomMenu);
  const dispatch = useDispatch(bottomMenu);
  const { status, connectId, onConnect } = useConnectWallet();

  async function handleClickConnectButton() {
    if (status !== 'connect') onConnect();
  }

  function handleClickMask() {
    dispatch(bottomMenu.actions.setClose())
  }
  function handleClickLink() {
    dispatch(bottomMenu.actions.setClose())
  }
  function handleClickLanguageButton() {
    dispatch(bottomMenu.actions.setClose())
  }

  useEffect(() => {
    lockWindow(open);
  }, [open]);

  return (
    <Root>
      <Mask display={open ? 'show' : 'hide'} onClick={handleClickMask} />
      <Wrapper display={open ? 'show' : 'hide'}>
        <MainButtons>
          <Button as="a"
            href={wording.white_paper.link}
            target="_blank"
            className="white-paper">
            { wording.white_paper.title }
          </Button>
          <Button className="connect-wallet" onClick={handleClickConnectButton}>
            { status === 'connect' ? 
              <span>{ connectId }</span>
              : 
              <span>{ wording.connect_wallet }</span>
            }
          </Button>
        </MainButtons>
        <BottomMenuWrapper>
          <LinksList className="link-list" data={wording.links} onLinkClick={handleClickLink} />
          <BottomButtons>
            <SocialsList data={wording.socials} />
            <LanguageButton className="lang" onButtonClick={handleClickLanguageButton} />
          </BottomButtons>
        </BottomMenuWrapper>
      </Wrapper>
    </Root>
  )
}

const Root = styled.div`

`

const Mask = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, .5);
  z-index: 4;
  transition: all .3s ease;
  display: none;
  ${respondTo.md} {
    display: block;
    ${({ display }) => display === 'show' && css`
      opacity: 1;
      visibility: visible;
    `}
    ${({ display }) => display === 'hide' && css`
      opacity: 0;
      visibility: hidden;
    `}
  }
`

const Wrapper = styled.div`
  position: fixed;
  bottom: -1px;
  left: 0;
  z-index: 5;
  ${respondTo.md} {
    background-color: ${colors.background};
    padding-bottom: 35px;
    width: 100%;
    transition: transform .3s ease;
    ${({ display }) => display === 'show' && css`
      transform: 0;
    `}
    ${({ display }) => display === 'hide' && css`
      transform: translateY(calc(100% - 68px));
    `}
  }
`

const BottomMenuWrapper = styled.div`
  display: none;
  ${respondTo.md} {
    display: block;
  }
`

const MainButtons = styled.div`
  padding: 0 40px;
  padding-bottom: 32px;
  ${respondTo.md} {
    padding: 16px 20px;
  }
`

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 5px;
  width: 150px;
  height: 36px;
  font-weight: 700;
  font-size: 16px;
  background-color: ${colors.black};
  color: ${colors.background};
  &.white-paper {
    background-color: ${colors.orange};
    color: ${colors.black};
    transition: all .3s ease;
    &:hover {
      background-color: ${colors.lightOrange};
    }
  }
  &.connect-wallet {
    transition: all .3s ease;
    &:hover {
      background-color: ${colors.orange};
      color: ${colors.black};
    }
  }
  & + a, & + button {
    margin-left: 8px;
  }
  ${respondTo.md} {
    width: calc(50% - 8px);
    font-size: 14px;
  }
`
const BottomButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .lang {
    margin-left: 8px;
  }
`


export default Bottom;