import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';
import useWording from '../../utils/useWording';
import { respondFrom, respondTo } from '../../utils/responsive';
import Carousel from '../../components/Carousel';
import ModalBox from '../../components/ModalBox';
import Text from '../../components/Text';

const RealityAndVirtual = () => {
  const wording = useWording('reality_and_virtual');
  const mouseStateRef = useRef({
    click: false,
    move: false
  });
  const [ currentIndex, setCurrentIndex ] = useState(null);

  function handleCloseModal() {
    setCurrentIndex(null)
  }

  function handleClickItem(e) {
    if (!mouseStateRef.current.move) {
      const index = Number(e.currentTarget.dataset.index);
      setCurrentIndex(index);
    }
  }

  function handleMouseDown() {
    mouseStateRef.current.click = true;
    mouseStateRef.current.move = false;
  }
  function handleMouseMove() {
    if (mouseStateRef.current.click) {
      mouseStateRef.current.move = true;
    }
  }
  function handleMouseUp() {
    mouseStateRef.current.click = false;
  }
  
  return (
    <Root>
      <ModalBox open={currentIndex !== null} onClose={handleCloseModal}>
        { currentIndex !== null &&
          <ModalContent extend={wording.list[currentIndex].icon === ""}>
            <div className="info">
              <Text className="title">{wording.list[currentIndex].title}</Text>
              <Text className="content"  dangerouslySetInnerHTML={{ __html: wording.list[currentIndex].content }} />
            </div>
            { wording.list[currentIndex].icon !== "" &&
              <img className="image" src={wording.list[currentIndex].icon} alt="" />
            }
          </ModalContent>
        }
      </ModalBox>
      <Heading>{ wording.heading }</Heading>
      <Content>{ wording.content }</Content>
      <List>
        { wording.list.map((item, i) => 
          <li>
            <Item
              key={i}
              display={i+1 === wording.process ? 'active': ''}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onClick={handleClickItem}
              data-index={i}>
              <div className="wrap">
                <div className="box">
                  <div className="head">
                    <span>
                      { wording.step_label } {i+1} { item.percent }
                    </span>
                    { wording.process === i+1 && <span>{ wording.process_label }</span>}
                  </div>
                  <div className="title">{item.title}</div>
                </div>
              </div>
            </Item>
          </li>
        ) }
      </List>
      <CarouselWrapper>
        <Carousel slides={4}>
          { wording.list.map((item, i) => 
            <Item
              key={i}
              display={i+1 === wording.process ? 'active': ''}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onClick={handleClickItem}
              data-index={i}>
              <div className="wrap">
                <div className="box">
                  <div className="head">
                    <span>
                      { wording.step_label } {i+1} { item.percent }
                    </span>
                    { wording.process === i+1 && <span>{ wording.process_label }</span>}
                  </div>
                  <div className="title">{item.title}</div>
                </div>
              </div>
            </Item>
          ) }
        </Carousel>
      </CarouselWrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: 0 40px;
  padding-bottom: 90px;
  overflow: hidden;
  ${respondTo.md} {
    padding: 0 20px;
    padding-top: 85px;
    padding-bottom: 90px;
  }
`

const Heading = styled(Text)`
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 700;
  ${respondTo.md} {
    margin-bottom: 12px;
    font-size: 22px;
  }
`

const Content = styled(Text)`
  font-size: 18px;
  font-weight: 400;
  ${respondTo.md} {
    font-size: 16px;
  }
`

const CarouselWrapper = styled.div`
  position: relative;
  margin: 0 -6px;
  margin-top: 32px;
  ${respondTo.md} {
    display: none;
  }
`

const List = styled.ul`
  display: none;
  margin: 0 -6px;
  margin-top: 32px;
  > li {
    margin-bottom: 8px;
  }
  ${respondTo.md} {
    display: block;
  }
`

const Item = styled.div`
  padding: 0 6px;
  box-sizing: border-box;
  cursor: pointer;
  .wrap {
    position: relative;
    border: 1px solid ${colors.black};
    width: 275px;
    height: 275px;
    overflow: hidden;
    ${respondTo.md} {
      width: 100%;
      height: 170px;
    }
    .box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      transition: background .3s ease;
      ${({ display }) => display === 'active' && css`
        background: ${colors.orange};
      `}
      ${respondFrom.md} {
        &:hover {
          background: ${colors.orange};
          ${({ display }) => display === 'active' && css`
            background: ${colors.lightOrange};
          `}
        }
      }
      ${respondTo.md} {
        padding: 10px;
      }
      .head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 18px;
        ${respondTo.md} {
          flex-direction: column;
        }
      }
      .title {
        font-size: 48px;
        font-weight: 700;
        line-height: 1;
        white-space: break-spaces;
        ${respondTo.md} {
          font-size: 28px;
          white-space: inherit;
        }
      }
    }
  }
`


const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 74px 106px;
  width: 1340px;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  background: ${colors.background};
  box-sizing: border-box;
  ${respondTo.md} {
    display: block;
    padding: 28px 20px;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
  > .info {
    width: 475px;
    max-width: 48%;
    ${({ extend }) => extend && css`
      width: 100%;
      max-width: 100%;
    `}
    ${respondTo.md} {
      margin-bottom: 22px;
      width: 100%;
      max-width: 100%;
    }
    .head {
      display: flex;
      margin-bottom: 28px;
      ${respondTo.md} {
        margin-bottom: 20px;
      }
      span {
        display: inline-block;
        font-size: 30px;
        ${respondTo.md} {
          font-size: 28px;
        }
        &:first-child { font-weight: 700; margin-right: 30px;}
      }
    }
    .title {
      margin-bottom: 18px;
      font-size: 30px;
      font-weight: 500;
      ${respondTo.md} {
        font-size: 22px;
      }
    }
    .content {
      font-size: 18px;
      font-weight: 400;
      white-space: break-spaces;
      ${respondTo.md} {
        font-size: 16px;
      }
    }
  }
  > .image {
    display: block;
    width: 550px;
    max-width: 48%;
    height: 100%;
    ${respondTo.md} {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
`

export default RealityAndVirtual;