import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import LanguageHelper from '../LanguageHelper';

const LanguageButton = ({ onButtonClick, ...props }) => {
  const { lang } = useSelector(state => state.language);
  const [ selectedLang, setSelectedLang ] = useState(null);
  const { pathname } = useLocation();

  function handleClickLink(e) {
    const lang = e.currentTarget.dataset.lang;
    setSelectedLang(lang);
    onButtonClick && onButtonClick();
  }
  
  function getCurrentPathName() {
    let currentPathname =(' ' + pathname).slice(1);
    return currentPathname.replace('/zh-TW', '');
  }

  function getCurrentZhPathname() {
    let currentPathname =(' ' + pathname).slice(1);
    if (currentPathname.includes('/zh-TW')) {
      return currentPathname;
    }
    else {
      return `/zh-TW${pathname}`
    }
  }
  
  return (
    <Root {...props}>
      { selectedLang &&
        <LanguageHelper selectedLang={selectedLang} />
      }
      { lang !== 'en' &&
        <LinkItem data-lang="en" to={getCurrentPathName()} onClick={handleClickLink}>EN</LinkItem>
      }
      { lang !== 'zh-TW' &&
        <LinkItem data-lang="zh-TW" to={getCurrentZhPathname()} onClick={handleClickLink}>中文</LinkItem>
      }
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
`

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 2px solid ${colors.black};
  padding: 3px 6px 2px;
  height: 28px;
  color: ${colors.mainColor};
  font-size: 12px;
  box-sizing: border-box;
  transition: all .3s ease;
  ${respondTo.md} {
    height: 22px;
  }
  &:hover {
    border: 2px solid ${colors.orange};
    color: ${colors.orange};
  }
`

export default LanguageButton;